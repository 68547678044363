import React, { Component, useEffect, useRef, useState } from 'react';
import { jsx, css } from '@emotion/react';
import {
    wrap,
    subHeading,
    fieldRow,
    mainContent,
    twoCol,
    inputField,
    label1,
    label2,
    fieldBox,
    selectField,
    tableRow,
    dataTable,
    hideOption
} from '../Styles';
import {
    Button, Radio, RadioGroup, FormControl, FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@material-ui/core';
import FormData from 'form-data';
import MappingFieldsOverLay from './MappingFieldsOverLay';
import './MappingFields.css';
import { UploadFile, GetMappingFields } from '../../api/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
const MappingScreen = (props) => {
    const [openOverLay, setOpenOverLay] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [configType, setConfigType] = useState('1');
    const [customer, setCustomer] = useState();
    const [orgType, setOrgType] = useState();
    const [mappings, setMappings] = useState();
    const [existingMappingList, setExistingMappingList] = useState([]);
    const fileRef = useRef();
    //let { orgType, id } = props.match.params;

    //const { orgType, id } = useParams();

    useEffect(() => {
        let { id, Org, Config, subConfig, orgType } = props.match.params;
        setOrgType(orgType);
         setCustomer(id);
        
        GetMappingFields({ customer: id, config_type: Config, sub_config_type: subConfig }, function (response) {
            

            if (response.status == 'success') {
                setExistingMappingList(response.response);
            }
        });
    }, []);

    const openDialogue = () => {
        setOpenOverLay(!openOverLay);
    }

    const onFileChange = (file) => {
        file.preventDefault();
        let val = file.target.files[0];
        setSelectedFile(val);
    };

    const uploadfile = () => {
        if (!selectedFile) {
            toast.info(`Please select file to upload.`, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            return;
        }
        let { Config, subConfig } = props.match.params;
        let formData = new FormData();
        formData.append("clients_file", selectedFile);
        formData.append("config_type", Config);
        formData.append("sub_config_type", subConfig);
        formData.append("customer", customer);

        UploadFile(formData, function (response) {
            //file.target.value = '';
            if (response && response.status == 'success') {
                console.log(response.response, 'response');
                var result = {
                    ConfiguredFields: response.response.config_properties,
                    FileFields: response.response.file_properties,
                    MappingSet: response.response.mapping_set,
                    TransactionId: response.response.transaction_id,
                    Customer: customer
                };

                setMappings(result);
                setOpenOverLay(prev => !prev);

                if (fileRef) fileRef.current.value = '';
            }
            else {
                toast.error(response.message, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 });
            }
        });
    };

    const onConfigChange = (event) => {
        var configVal = event.target.value;
        setConfigType(configVal);
    }

    const overLayCallBack = () => {
        GetMappingFields({ customer: customer }, function (response) {
            

            if (response.status == 'success') {
                setExistingMappingList(response.response);
            }
        });
    }

    const tableRowClick = (row) => {
        var result = {
            ConfiguredFields: Object.keys(row.mapping_set),
            FileFields: row.file_properties,
            MappingSet: row.mapping_set,
            TransactionId: row.id,
            Customer: customer
        };

        setMappings(result);
        setOpenOverLay(prev => !prev);
    }
    let { Config, subConfig } = props.match.params;
    return (
        <>
            <div css={fieldRow} style={{ justifyContent: 'center', marginTop: '30px' }}>
                <div style={{ textAlign: 'center' }} >
                    {orgType == '3' ?
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={onConfigChange}
                                value={configType}
                            >
                                <FormControlLabel
                                    value="1"
                                    control={
                                        <Radio
                                            style={{
                                                color: '#007bff'
                                            }}
                                        />
                                    }
                                    label="Client"
                                />
                                <FormControlLabel
                                    value="2"
                                    control={
                                        <Radio
                                            style={{
                                                color: '#007bff'
                                            }}
                                        />
                                    }
                                    label="Family"
                                />
                            </RadioGroup>
                        </FormControl>
                        : ''}
                    <br /><br />
                    <input name="uploadfile" ref={fileRef} type="file" onChange={onFileChange} />
                    <Button
                        type="submit"
                        size="small"
                        variant="contained"
                        //disabled={this.state.isLoading}
                        onClick={uploadfile}
                        //onClick={openDialogue}
                        style={{
                            marginRight: 10,
                            backgroundColor: '#007bff',
                            color: '#fff'
                        }}
                    >
                        Upload
                    </Button>
                </div>

                <>
                    <br />
                    <br />
                    <TableContainer component={Paper} style={{ margin: '15px', width: 'auto' }}>
                        <Table aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <b>ConfigType</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>Created Date</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {existingMappingList && existingMappingList.length > 0 ? existingMappingList.map((row) => (
                                    <TableRow key={row.Id} style={{ height: '50px', cursor: 'pointer' }} onClick={(e) => { tableRowClick(row) }}>
                                        <TableCell style={{ padding: '5px 15px' }} component="td" scope="row">
                                            {row.config_type_name}
                                        </TableCell>
                                        <TableCell style={{ padding: '5px 15px' }} component="td" scope="row">
                                            {row.date_created}
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell colSpan={2}>
                                        No Mapping Fields Config Found.
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            </div>
            {openOverLay ?
                <MappingFieldsOverLay mappingFields={mappings} isOpen={openOverLay} close={setOpenOverLay} callBack={overLayCallBack} Config={Config} subConfig={subConfig}/>
                : ''}
        </>
    )
}

export default MappingScreen;