import React, { Component, useEffect, useRef, useState } from 'react';
import { jsx, css } from '@emotion/react';
import {
    wrap,
    subHeading,
    fieldRow,
    mainContent,
    twoCol,
    inputField,
    label1,
    label2,
    fieldBox,
    selectField,
    tableRow,
    dataTable,
    hideOption
} from '../Styles';
import {
    Button, Radio, RadioGroup, FormControl, FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@material-ui/core';
import FormData from 'form-data';
// import MappingFieldsOverLay from './MappingFieldsOverLay';
import {
    UploadFile, RegisterCustomerApp, GetCustomerApp
    , UpdateCustomerApp, RegenerateToken, DeleteCustomerApp
} from '../../api/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import './APIKeysConfigure.css';

const APIKeysConfigure = (props) => {
    const [configType, setConfigType] = useState('1');
    const [customer, setCustomer] = useState();
    const [orgType, setOrgType] = useState();
    const [mappings, setMappings] = useState();
    const [allFields, setAllFields] = useState([]);
    const [isEdit, setEdit] = useState(false);
    const [isValidation, setValidation] = useState(false);
    const [customerApp, setCustomerApp] = useState();
    const [isRegen, setRegen] = useState(false);
    const fileRef = useRef();

    const filedsList = [

        {
            title: 'App Name',
            key: 'app_name',
            value: ''
        },
        {
            title: 'App Domain',
            key: 'app_domain',
            value: ''
        },
        {
            title: 'App Ip',
            key: 'app_ip',
            value: ''
        },
        {
            title: 'API Key',
            key: 'api_key',
            value: '',
            notRequired: true,
        },
    ];

    useEffect(() => {
        let { orgType, id } = props.match.params;
        setOrgType(orgType);
        setCustomer(id);

        setAllFields(filedsList);

        GetCustomerApp({ customer: id }, function (response) {
            
            if (response.status == 'success' && response.response) {
                var record = response.response ? response.response : null;
                setCustomerApp(record);
                var temp = [...filedsList];

                if (record) {
                    temp.map(item => {
                        if (item.key in record) {
                            item.value = record[item.key];
                        }
                    });
                }
                else {
                    setEdit(true);
                }

                setAllFields(temp);
            }
        });
    }, [isRegen]);

    const handleInputChange = (event, field) => {
        var temp = [...allFields];
        temp.map(item => {
            if (item.key == field.key) {
                item.value = event.target.value;
            }
        })

        setAllFields(temp);
    };

    const onEdit = (event) => {
        setEdit(!isEdit);
    }

    const onSave = (event) => {
        var payLoad = new FormData();
        payLoad.append("customer", customer);
        var validation = false;
        allFields.filter(item => !item.notRequired).map(item => {
            if (!item.value || (item.key == 'app_ip' && validateIp(item.value))) {
                validation = true;
                return;
            }

            payLoad.append(item.key, item.value);
        });

        setValidation(validation);

        if (validation) {
            return;
        }

        if (isEdit && customerApp) {
            UpdateCustomerApp(payLoad, customerApp.id, function (response) {
                if (response.status == 'success') {
                    toast.info(`Successfully updated`, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                }
                else {
                    toast.error(response.message ? response.message : JSON.stringify(response.response), { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                }
            })
        }
        else {
            RegisterCustomerApp(payLoad, function (response) {
                if (response.status == 'success' && response.response) {
                    setRegen(!isRegen);
                    toast.info(`Successfully saved`, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                }
                else {
                    toast.error(response.message ? response.message : JSON.stringify(response.response), { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                }
            });
        }
    }

    const reGenerate = () => {
        RegenerateToken({ appId: customerApp.id }, function (response) {
            if (response.status == 'success') {
                setRegen(!isRegen);
                toast.info(`Successfully generated`, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            }
            else {
                toast.error(response.message ? response.message : JSON.stringify(response.response), { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            }
        })
    };

    const revokeToken = () => {
        DeleteCustomerApp({ appId: customerApp.id }, function (response) {
            if (response.status == 'success') {
                setRegen(!isRegen);
                toast.info(`Successfully deleted`, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            }
            else {
                toast.error(response.message ? response.message : JSON.stringify(response.response), { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            }
        })
    };

    const validateIp = (ip) => {

        var ipValid = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip);

        if (ipValid) {
            return null;
        }

        return 'Please enter valid ip address';
    }

    return (
        <>
            <div css={fieldRow} style={{ justifyContent: 'center', marginTop: '30px' }}>
                <>
                    {allFields.map((field) => (
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label font-weight-bold ">{field.title}:</label>
                            <div className="col-sm-7">
                                <input type="text" className="form-control" name={field.name} value={field.value} disabled={field.notRequired ? field.notRequired : !isEdit} onChange={(e) => { handleInputChange(e, field) }} required />
                                {isValidation && !field.value ?
                                    <small id="emailHelp" class="form-text text-danger">
                                        Please enter {field.title}
                                    </small>
                                    : ''}

                                {
                                    isValidation && field.value ?
                                        (field.key == 'app_ip' ? <small id="emailHelp" class="form-text text-danger"> {validateIp(field.value)}</small> : '') : ''
                                }
                            </div>
                        </div>
                    ))}

                    <div className="form-group row" style={{ textAlign: 'right' }}>
                        <label className="col-sm-2 col-form-label font-weight-bold "></label>
                        <div className="col-sm-7">
                            {isEdit ?
                                <Button className='btnStyle' onClick={onSave}>{customerApp ? 'Update' : 'Save'}</Button>
                                : ''}
                            <Button className='btnStyle' onClick={onEdit}> {isEdit ? 'Cancel' : 'Edit'}</Button>

                            {customerApp ? <>
                                <Button className='btnStyle' onClick={reGenerate}>{'ReGenerate'}</Button>
                                <Button className='btnStyle' onClick={revokeToken}> {'Revoke'}</Button>
                            </> : ''}
                        </div>
                    </div>
                </>
            </div>
            {/* {openOverLay ?
                <MappingFieldsOverLay mappingFields={mappings} isOpen={openOverLay} close={setOpenOverLay} />
                : ''} */}
        </>
    )

}

export default APIKeysConfigure;